import React from 'react'
import { graphql } from 'gatsby'

//components
import RichContent from '../components/content/RichContent'
import Marque from '../components/zone/Marque'

const TemplateService = ({ data }) => {
  return (
    <div className='service'>
      <div className='container'>
        <RichContent myRichContent={data.c.content} />
      </div>
      <Marque />
    </div>
  )
}

export const query = graphql`
  query($id: String) {
    c: contentfulService(id: { eq: $id }) {
      title
      content {
        json
      }
    }
  }
`
export default TemplateService
